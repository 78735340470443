/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { Layout } from "syngenta-digital-cropwise-react-ui-kit";
import { useAuth } from "./lib/auth/AuthContext";
import { AppRoutes } from "./modules";
import { ErrorBoundary } from "./shared/components/ErrorBoundary";
import { Loader } from "./shared/components/Loader";
import { GlobalFooter } from "./shared/layout/GlobalFooter";
import { GlobalHeader } from "./shared/layout/GlobalHeader";
import { SidebarMenu } from "./shared/layout/Sidebar";
import { ThemeProvider } from "./shared/layout/ThemeProvider";
const RequestAccessModal = lazy(
  async () => await import("./shared/components/licenceAccess/LicenceModel")
);
const { Content } = Layout;
const HallOfEntitiesController = lazy(
  async () => await import("./modules/hall-of-entities/HallOfEntities")
);

export default function App(): JSX.Element | null {
  const { user, logoutStatus, hasLicenceAccess } = useAuth();

  if (!user) {
    return null;
  }

  if (logoutStatus) {
    return <Loader mask={true} message="Logging out of the application..." />;
  }

  return (
    <ErrorBoundary>
      <ThemeProvider>
        <GlobalHeader />
        <Routes>
          <Route
            path="/my-cropwise/*"
            element={
              <Suspense fallback={<Loader mask={true} message="Loading..." />}>
                <HallOfEntitiesController />
              </Suspense>
            }
          />
          <Route
            path="/*"
            element={
              <div className="app-root">
                <Layout className="cw-page-container">
                  <Suspense
                    fallback={<Loader mask={true} message="Loading..." />}
                  >
                    <SidebarMenu />
                    <Layout className="main-layout">
                      <Content className="main-content">
                        {!hasLicenceAccess ? (
                          <RequestAccessModal />
                        ) : (
                          <AppRoutes />
                        )}
                      </Content>
                      <GlobalFooter />
                    </Layout>
                  </Suspense>
                </Layout>
              </div>
            }
          />
        </Routes>
      </ThemeProvider>
    </ErrorBoundary>
  );
}
