import { ProfileDropdown } from "@cw-elements/profile-dropdown";
import { type FC, type ReactElement } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { logout } from "src/lib/auth/AuthService";
import { TopNavBar } from "syngenta-digital-cropwise-react-ui-kit";
import Logo from "../../assets/images/logo.svg";
import Organizations from "./Organizations";
import "./header.less";

const Organisation = (): JSX.Element => <Organizations />;
export const GlobalHeader: FC = (): ReactElement => {
  const location = useLocation();
  const navigate = useNavigate();
  const navigateToHallOfEntities = (): void => {
    navigate("/");
  };

  return (
    <TopNavBar
      logo={
        <button
          className="logo"
          style={{ background: `url(${Logo}) no-repeat center center` }}
          aria-label="Navigate to home"
          onClick={navigateToHallOfEntities}
        />
      }
      leftSection={
        location.pathname.includes("my-cropwise") ? null : <Organisation />
      }
      newDesign={true}
      userProfile={
        <ProfileDropdown availableUnitSystems={[]} onLogout={() => logout()} />
      }
    />
  );
};
