import { type CWEnv, initCWElements } from "@cw-elements/config";
import { initMfes } from "@cw-elements/mfe-setup/init";
import ReactDOM from "react-dom/client";
import "syngenta-digital-cropwise-react-ui-kit/dist/styles/cw-ui-kit.less";
import App from "./App";
import AppProviders from "./context";
import "./index.less";
import reportWebVitals from "./reportWebVitals";
import ConfigMissing from "./shared/config-missing";
import { getAccessToken } from "./shared/utils/axios";

const isConfigurationGood = !!(
  process.env.REACT_APP_ENV !== null &&
  process.env.REACT_APP_ACCOUNTS_ENDPOINT !== null &&
  process.env.REACT_APP_BASE_API_URL !== null &&
  process.env.REACT_APP_API_URL !== null &&
  process.env.REACT_APP_OAUTH_CLIENT_ID !== null
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

async function bootstrap(): Promise<void> {
  const mfeUrl = process.env.REACT_APP_CW_ELEMENTS_MFES;
  if (mfeUrl) {
    let token;
    try {
      token = getAccessToken() ?? undefined;
      const cwElementsConfig = {
        environment: process.env.REACT_APP_CW_ELEMENTS_ENVIRONMENT as CWEnv,
        token,
        hostAppId: process.env.REACT_APP_ID,
      };
      initCWElements(cwElementsConfig);
      await initMfes({
        "entities-hall": `${mfeUrl}/entities-hall`,
      });
      initApp();
    } catch (error) {
      console.error(error);
    }
  }
}
/* eslint-disable */
// TODO: Remove the eslint-disable comment after the issue is resolved
(async function () {
  await bootstrap().catch((e) => console.error(e));
})();

function initApp(): void {
  root.render(
    <>
      {isConfigurationGood ? (
        <AppProviders>
          <App />
        </AppProviders>
      ) : (
        <ConfigMissing />
      )}
    </>
  );
}

reportWebVitals();
