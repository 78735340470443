import { Alert } from "antd";
import { type ReactNode, type FC, type ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Warning } from "../../assets/images/warning.svg";
import "./styles.less";
interface AlertComponentInterface {
  type: "success" | "info" | "warning" | "error";
  message: string;
  description?: string;
  icon?: ReactNode;
  closable?: boolean;
}
export const AlertComponent: FC<AlertComponentInterface> = ({
  type,
  message,
  description,
  icon,
  closable,
}): ReactElement => {
  const { t } = useTranslation();
  return (
    <Alert
      closable={closable}
      message={t(message)}
      description={t(description ?? "")}
      type={type}
      showIcon
      className="alert-styles"
      icon={icon ?? <Warning />}
    />
  );
};
