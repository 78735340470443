import { type ReactElement } from "react";
import "./style.less";

const ConfigMissing = (): ReactElement => {
  return (
    <div className="config-message">
      <div
        style={{
          height: "100vh",
          padding: "50px",
          position: "absolute",
          top: "70%",
        }}
      >
        <h1 style={{ color: "#FFF" }}>
          Cropwise Carbon: Your setup is missing some configuration required for
          the application to run.
        </h1>
        <p>Please check the README.md file for details.</p>
      </div>
    </div>
  );
};

export default ConfigMissing;
